import { setBlockTracking as _setBlockTracking, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  return _cache[0] || (
    _setBlockTracking(-1),
    (_cache[0] = (_openBlock(), _createElementBlock("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: "1em",
      height: "1em",
      "aria-hidden": "true",
      class: "outline-info_svg__icon",
      viewBox: "0 0 24 24"
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("path", {
        fill: "currentColor",
        d: "M11 7h2v2h-2zm0 4h2v6h-2zm1-9a10 10 0 1 0 0 20 10 10 0 0 0 0-20m0 18a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16"
      }, null, -1))
    ]))).cacheIndex = 0,
    _setBlockTracking(1),
    _cache[0]
  )
}
export default { render }